"use client"
import { Autocomplete, AutocompleteItem } from "@nextui-org/autocomplete"
import { Button } from "@nextui-org/button"
import { DateInput } from "@nextui-org/date-input"
import { Input } from "@nextui-org/input"
import { useToast } from "@repo/ui/hooks/use-toast"
import { cn } from "@repo/utils/utils"
import { generateNames, generateOptions, randomNames, } from "@repo/web/lib/actions/chinese-names/chinese-names-generator"
import type { ChineseNamesDataArrayType } from "@repo/web/lib/actions/chinese-names/types"
import { FavoritesProvider } from "@repo/web/lib/components"
import { CHINESE_NAMES_OPTIONS, FAVORITES_SS_KEY } from "@repo/web/lib/consts"
import type { BasicToolOptions } from "@repo/web/lib/types"
import { fetchDelete, fetchGet } from "@repo/web/lib/utils"
import { readStreamableValue } from "ai/rsc"
import { useLocale, useTranslations } from "next-intl"
import { throttle } from "radash"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { useCopyToClipboard } from "react-use"
import { NameCardCompact, NameCardList, NameCardListHistory } from "./(views)/cards"


type OpType = "instant" | "smart" | "favorites"

type ToggleButtonsProps = {
    selected: OpType
    onSelect: (e: OpType) => void
}

const ToggleButtons = memo<ToggleButtonsProps>(({ selected, onSelect }) => {
    const t = useTranslations("ChineseNamesGenerator")
    return (
        <div className="relative mb-12">
            <div className="absolute inset-0 top-[-50px] flex justify-center items-center">
                <div className="bg-gradient-to-r from-[#F9B7FA] to-[#ED67F0] rounded-lg shadow-lg">
                    <div className="flex gap-1 md:gap-2 p-2">
                        <Button
                            className={`text-black md:font-bold min-w-24 md:w-48  ${selected === "instant" ? "bg-white" : "bg-transparent"}`}
                            startContent={<span className="">📋</span>}
                            onClick={() => onSelect("instant")}
                        >
                            {t("operations.instant.title")}
                        </Button>
                        <Button
                            className={`text-black md:font-bold min-w-24 md:w-48 ${selected === "smart" ? "bg-white" : "bg-transparent"}`}
                            startContent={<span className="">✨</span>}
                            onClick={() => onSelect("smart")}
                        >
                            {t("operations.smart-ai.title")}
                        </Button>
                        <Button
                            className={`text-black md:font-bold min-w-24 md:w-48 ${selected === "favorites" ? "bg-white" : "bg-transparent"}`}
                            startContent={<span className="">❤️</span>}
                            onClick={() => onSelect("favorites")}
                        >
                            {t("operations.favorites.title")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
})


type OperationOptionsProps<T = Record<string, any>> = {
    data?: T
    onChange: (options: T) => void
    hidden?: string[]
}

export const OperationOptions = memo<OperationOptionsProps>(({ data = {}, onChange, hidden }) => {
    const locale = useLocale()
    const t = useTranslations("ChineseNamesGenerator")
    const setInputValue = throttle({ interval: 500 }, (key: string, value: any) => {
        onChange({ ...data, [key]: value })
    })


    const renderedOptions = (it: any) => {
        if (it.type === 'select') {
            return (
                <Autocomplete
                    key={it.key}
                    label={t(`options.${it.key}.label`)}
                    labelPlacement="outside"
                    placeholder={t(`options.${it.key}.placeholder`)}
                    className="mb-2 md:mb-0"
                    selectedKey={data[it.key]!}
                    variant="faded"
                    size="lg"
                    onSelectionChange={(value) => setInputValue(it.key, value)}
                >
                    {
                        it.key === 'surname' ? ((it.options as any).map(({ label, value }: any) => (
                            <AutocompleteItem key={value} value={value}>
                                {label === 'any' ? t(`options.${it.key}.opt-${label}`) : label}
                            </AutocompleteItem>
                        ))) : (it.options as any).map((v: any) => (
                            <AutocompleteItem key={v.value} value={v.value}>
                                {t(`options.${it.key}.opt-${v.label}`)}
                            </AutocompleteItem>
                        ))
                    }
                </Autocomplete>
            )
        }

        if (it.type === 'date') {
            return (
                <DateInput lang={locale} granularity="hour" hourCycle={24} labelPlacement="outside" variant="faded" key={it.key} size="lg" label={t(`options.${it.key}.label`)} />
            )
        }

        if (it.type === 'input') {
            return (
                <div className="md:col-span-3" key={it.key}>
                    <Input
                        size="lg"
                        label={t(`options.${it.key}.label`)}
                        placeholder={t(`options.${it.key}.placeholder`)}
                        variant="faded"
                        onChange={(e) => setInputValue(it.key, e.target.value)}
                    />
                </div>
            )
        }
    }

    return (<div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            {
                CHINESE_NAMES_OPTIONS.filter(k => !hidden?.includes(k['key'])).map((it) => renderedOptions(it))
            }
        </div>
    </div>
    )
})
OperationOptions.displayName = "OperationOptions"

type OperationsProps = {
    type: "instant" | "smart" | "favorites"
    isPending?: boolean
    onRandom: () => void
    onGeneration: () => void
    onGenerateOptions: () => void
}

const Operations = memo<OperationsProps>(
    ({ type, isPending, onRandom, onGeneration, onGenerateOptions }) => {
        const t = useTranslations("ChineseNamesGenerator")

        if (type === "favorites") {
            return <></>
        }

        if (type === "instant") {
            return (
                <div className="flex justify-center items-center">
                    <Button
                        isLoading={isPending}
                        size="lg"
                        color="primary"
                        className="bg-gradient-to-r from-orange-400 to-red-500 text-white"
                        startContent={<span className="mr-1">✨</span>}
                        onClick={onRandom}
                    >
                        {t("operations.instant.btn-operate")}
                    </Button>
                </div>
            )
        }

        return (
            <div className="flex justify-center items-center gap-5">
                {/* <Button isLoading={isPending} isIconOnly variant="flat" color="warning" size="lg" onClick={onGenerateOptions}><Sparkles
                    className='text-yellow-400'/></Button> */}
                <Button
                    isLoading={isPending}
                    size="lg"
                    color="success"
                    className="bg-gradient-to-r from-green-400 to-blue-500 text-white"
                    onClick={onGeneration}
                >
                    <span className="mr-1">🪄</span> {t("operations.smart-ai.btn-operate")}
                </Button>
            </div>
        )
    },
)

type FavoritesProps = {
    data: any[]
}

const Favorites = memo<FavoritesProps>(({ data }) => {
    const [values, setValues] = useState(data)
    const t = useTranslations("ChineseNamesGenerator")
    const [, copy] = useCopyToClipboard()
    const handleCopy = useCallback(
        (name: string) => {
            copy(name)
        },
        [copy],
    )
    const handleDropFavorite = (item: any) => {
        return fetchDelete(`/api/tools/chinese-names-generator/favorite?id=${item.id}`).then(() => {
            setValues((prev) => prev.filter((it: any) => it.id !== item.id))
        })
    }

    return (
        <>
            {values.length ? (
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
                    {values.map((item: any, index) => (
                        <NameCardCompact
                            key={index}
                            {...item}
                            onDrop={() => handleDropFavorite(item)}
                            onCopy={handleCopy}
                        />
                    ))}
                </div>
            ) : (
                <div className="mx-auto text-center py-20 text-gray-400 text-medium">
                    {t("operations.favorites.empty")}
                </div>
            )}
        </>
    )
})

type Props = {
    tool: BasicToolOptions
    hidden?: string[],
    initialOptions?: Record<string, any>
}

export function ChineseNamesGenerator({ tool, hidden = [], initialOptions = {} }: Props) {
    const { toast } = useToast()
    const locale = useLocale()
    const t = useTranslations("ChineseNamesGenerator")
    const [selected, setSelected] = useState<OpType>("smart")
    const hiddenKeys = useMemo(() => selected === "smart" ? [...hidden] : [...hidden, "customRequirements", "birthday"], [selected])
    const isFavorites = useMemo(() => selected === "favorites", [selected])
    const emptyOptions = CHINESE_NAMES_OPTIONS.map(({ key }) => key).reduce((it, k) => {
        it[k] = ''
        return it
    }, {} as Record<string, string>)
    const [options, setOptions] = useState({ ...emptyOptions, ...initialOptions })

    const [isPending, setIsPending] = useState(false)
    const [data, setData] = useState<ChineseNamesDataArrayType>([])
    const [history, setHistory] = useState<any[][]>([])
    const [favorites, setFavorites] = useState<any[]>([])
    const updateData = throttle({ interval: 10 }, setData)

    useEffect(() => {
        fetchGet("/api/tools/chinese-names-generator/history", {
            toolId: tool.id,
        }).then((res) => {
            setHistory(res)
        })
    }, [locale])

    useEffect(() => {
        if (selected === "favorites") {
            fetchGet("/api/tools/chinese-names-generator/favorite", {
                toolId: tool.id,
            }).then((res) => {
                setFavorites(res ?? [])
            })
        }
    }, [selected])

    function updateHistory() {
        // 将当前data数据插入history的头部，如果history超过10个，则去掉最后一个
        if (data?.length > 0) {
            const newHistory = history
            newHistory.unshift(data);
            if (newHistory.length > 10) {
                newHistory.pop();
            }
            setHistory(newHistory)
        }
    }

    async function handleRandom() {
        setIsPending(true)
        try {
            updateHistory()
            setData([])
            const values = await randomNames({ locale, toolId: tool.id })
            setData(values as any)
            setIsPending(false)
        } catch (e) {
            console.error('随机生成异常：',e)
            toast({
                title: t("error.title"),
                description: t(`error.desc`),
            })
            setIsPending(false)
        }
    }

    async function handleGeneration() {
        setIsPending(true)
        try {
            updateHistory()
            updateData([])
            const streamValue = await generateNames(
                { locale, toolId: tool.id },
                options,
            )
            for await (const value of readStreamableValue(streamValue)) {
                // console.log("## 数据 ===> ", JSON.stringify(value))
                updateData(value.data)
            }
            setIsPending(false)
        } catch (e) {
            console.error('AI 生成异常：',e)
            toast({
                title: t("error.title"),
                description: t(`error.desc`),
            })
            setIsPending(false)
        }
    }

    async function handleGenerateOptions() {
        try {
            setIsPending(true)
            const options = await generateOptions()
            setOptions((prev) => ({ ...prev, ...options }))
            setIsPending(false)
        } catch (e) {
            console.error(e)
            toast({
                title: t("error.title"),
                description: t(`error.desc`),
            })
            setIsPending(false)
        }
    }

    return (<div className="bg-white w-full min-h-screen px-4 py-8 md:mt-12">
        <FavoritesProvider storageKey={`${FAVORITES_SS_KEY}${tool.id}`} toolId={tool.id} />
        <div className="max-w-7xl mx-auto">
            <div className="bg-gradient-to-b from-[#FEEDFE] to-white p-6 rounded-3xl mb-8">
                <ToggleButtons selected={selected} onSelect={setSelected} />
                {isFavorites ? (
                    <Favorites data={favorites} />
                ) : (
                    <>
                        <OperationOptions
                            data={options}
                            hidden={hiddenKeys as any}
                            onChange={setOptions} />

                        <Operations
                            type={selected}
                            isPending={isPending}
                            onRandom={handleRandom}
                            onGeneration={handleGeneration}
                            onGenerateOptions={handleGenerateOptions}
                        />
                    </>
                )}
            </div>
            <div className={cn("w-full", { hidden: isFavorites })}>
                {
                    data?.length > 0 && (
                        <NameCardList toolId={tool.id} isPending={isPending} data={data} />
                    )
                }

            </div>
            <div className="mb-8 transition duration-500 ease-in-out">
                <NameCardListHistory
                    toolId={tool.id}
                    isPending={isPending}
                    data={history}
                />
            </div>
        </div>
    </div>)
}
